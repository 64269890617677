import React from 'react'
import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'
import { serializers } from '@lib/serializers'

const Paragraph = ({ data }) => {
  const { shouldDecorateWithAngles } = data
  return (
    <section>
      <div
        className={cx('paragraph', {
          'has-block-angles': shouldDecorateWithAngles,
        })}
      >
        <BlockContent
          renderContainerOnSingleChild
          className="paragraph__content rc"
          blocks={data.content}
          serializers={serializers}
        />
      </div>
    </section>
  )
}

export default Paragraph
